<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card v-if="event">
      <v-alert type="warning" v-if="event.notPublished">{{$t('eventmanager.not-published-msg')}}</v-alert>
      <EventHeader :event="event"/>

      <v-alert v-if="$helpers.showRegistrationOptions(event)" tile color="grey lighten-3" class="registration-panel mt-n11 py-6">
        <v-btn :x-large="!$vuetify.breakpoint.xsOnly" :color="event.color || 'primary'" :dark="$helpers.isDarkColor(event.color || 'primary')" class="float-right" :href="event.reg_url" target="_blank">{{ event.reg_text || $t('events.register-now') }}</v-btn>
      </v-alert>

      <vue-markdown v-if="event.intro" class="markdown mx-4" :html="false">{{event.intro }}</vue-markdown>

      <div v-if="event.pinned_race_id">
        <RaceCustomView :event="event" :race="event.races.find(x => x.id == event.pinned_race_id)" />
        <v-divider/>
      </div>
      <div v-if="visibleRaces && visibleRaces.length > 0">
        <v-card-title class="subtitle">{{$t('events.races-and-leaderboards')}}</v-card-title>

        <div v-if="$helpers.isFutureEvent(event)" class="mx-4">
          <CountDownTimer :label="$t('events.rules.starts-in')" :countDownDate="event.from" />
        </div>
        
        <RaceGrid :items="visibleRaces.filter(x => x.visibility !== 'MUTED' || showMuted)" :event="event" class="px-4"></RaceGrid>
        <v-card-text v-if="hasMutedRaces" class="mb-0 pb-0">
          {{$t('events.muted-leaderboards-msg')}}
        </v-card-text>
        <v-card-actions v-if="hasMutedRaces">
          <v-btn :color="event.color || 'primary'" text @click="showMuted = !showMuted">{{ showMuted ? $t('events.muted-leaderboards-hide') : $t('events.muted-leaderboards-show')}}</v-btn>
        </v-card-actions>
        <p v-if="tenant.id == 'cofi'" class="ma-4">
          <v-btn :color="event.color || 'primary'" outlined :to="{name: 'eventFeed', params: {id: event.id}}">View Feed &raquo;</v-btn>
        </p>
      </div>

      <div v-if="$helpers.isActiveEvent(event)" class="mx-4">
        <CountDownTimer :label="$t('events.rules.finishes-in')" :countDownDate="event.till" />
      </div>

      <div v-if="photos && photos.length > 0">
        <v-card-title class="subtitle">{{$t('events.photos')}}</v-card-title>
        <PhotoGallery :photos="photos" :eventId="event.id" />
      </div>
  
      <v-container>
        <v-row>
          <v-col cols="12" md="8" class="px-0">
            <v-card-title v-if="event.description" class="subtitle">{{$t('events.about', {event: event.name})}}</v-card-title>
            <v-card-text>
              <vue-markdown class="markdown" :html="false">{{event.description }}</vue-markdown>
              <!-- <v-btn outlined class="mt-4 mr-4" color="primary" :to="{name: 'eventWelcome', params: {id:event.id}}">How it works</v-btn> -->
              <v-btn v-if="event.url" outlined class="mt-4 mr-4" :color="event.color || 'primary'" :href="event.url" target="_blank">{{$t('events.visit-website')}}</v-btn>
            </v-card-text>
            <div v-if="event.deeplink_url">
              <v-card-title class="subtitle pb-0">{{$t('events.download-app')}}</v-card-title>
              <v-card-text>
                <p>{{$t('events.download-app-msg')}}</p>
                <p>
                  <a class="mr-2" :href='event.deeplink_url' target="_blank"><img style="max-width:100%;" alt='Download on the App Store' src='https://sodisp.imgix.net/web/ios-app-store.png'/></a>
                  <a :href='event.deeplink_url' target="_blank"><img style="max-width:215px; margin: -15px;" alt='Get it on Google Play' src='https://sodisp.imgix.net/web/android-play-store.png'/></a>
                </p>
                <p class="text-muted">{{$t('events.download-app-disclaimer')}}</p>
              </v-card-text>
            </div>
            <div v-if="event.org">
              <v-card-title class="subtitle pb-0">{{$t('events.organizer')}}</v-card-title>
              <v-card-text>
              <v-btn outlined :color="event.color || 'primary'" :to="{name: 'organization', params: {id: event.org.id}}">{{event.org.name}}</v-btn>
              </v-card-text>
            </div>
            <div v-if="organizations.length > 0">
              <v-card-title class="subtitle pb-0">{{$t('events.organizer')}}</v-card-title>
              <v-col v-for="item in organizations" :key="item.id"
                cols="12" sm="6" md="6" lg="4"
              >
                <GroupCard :item="item" route-name="organizerView"></GroupCard>
              </v-col>
            </div>
            <div v-if="series.length > 0">
              <v-card-title class="subtitle pb-0">{{$t('events.part-of-series')}}</v-card-title>
              <v-col v-for="item in series" :key="item.id"
                cols="12" sm="6" md="6" lg="4"
              >
                <GroupCard :item="item" route-name="seriesView"></GroupCard>
              </v-col>
            </div>
            <div v-if="tileScores && tileScores.length > 0" class="mt-4">
              <v-card-title class="subtitle">Tiles this month</v-card-title>
              <v-card-text>Athletes participating in this event have scored points on these <router-link :to="{name: 'map'}">tiles</router-link>. Tile leaderboards start from scratch every new calendar month.</v-card-text>
              <v-simple-table fixed-header >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left">Tile</th>
                      <th class="text-left">Country</th>
                      <!-- <th class="text-left">Points</th> -->
                      <th class="text-left">Distance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in tileScores" :key="idx">
                      <td><v-icon>{{$helpers.getActivityIcon(item.type)}}</v-icon></td>
                      <td><router-link :to="{name: 'tile', params: {id: item.id, type: item.type.toLowerCase() }}">{{ item.name }}</router-link></td>
                      <td><span v-if="item.country_code" :title="item.country_code" :class="`flag-icon flag-icon-${item.country_code.toLowerCase() }`"></span></td>
                      <!-- <td class=""><ActivityScoreChip :points="item.score.total_pts"/></td> -->
                      <td>{{ item.total_dist | distance(event.unit) }} </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>            
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <EventJoinCard v-if="event.type !== 'HYBRID' && event.type !== 'RESULTS'" :event="event"/>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>

      <div v-if="event.type !== 'RESULTS'" class="grey lighten-4 ">
        <v-card-text>
          <vue-markdown v-if="event.rules_desc" class="markdown" :html="false">{{event.rules_desc }}</vue-markdown>
          <div v-else>
            <h3 class="headline">{{$t('events.rules.title')}}</h3>
            <ul class="mb-4 mt-2">
              <li>{{$t('events.rules.join_before', { from: $options.filters.localDate(event.from, "L LT"), till: $options.filters.localDate(event.till, "L LT") })}}</li>
              <li>{{$t('events.rules.period', { from: $options.filters.localDate(event.from, "L LT"), till: $options.filters.localDate(event.till, "L LT") })}}</li>
              <li v-if="event.allowed_history_days || event.allowed_history_days === 0">{{$t('events.rules.backloading', { days: event.allowed_history_days })}}</li>
              <li v-if="event.allow_manual_entry">{{$t('events.rules.manual-entry-allowed')}}</li>
              <li v-else>{{$t('events.rules.gps-only')}}</li>
            </ul>

            <p>
              {{$t('events.local-date-times-msg')}}
            </p>
          </div>
        </v-card-text>
        
      </div>
      <v-dialog v-model="showShareBib" width="600">
        <v-card>
          <v-card-title>
            {{ event.name }}
            <v-spacer/>
            <ShareButton color="primary" outlined/>
          </v-card-title>
          <img :src="bibImageUrl" style="max-width: 100%;"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showShareBib = false">{{$t('shared.close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import ShareButton from '@/components/ShareButton.vue';
import ActivityUploadForm from '@/components/ActivityUploadForm.vue';
import RaceGrid from '@/components/RaceGrid.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import RaceCustomView from '@/components/RaceCustomView.vue';
import EventHeader from '@/components/EventHeader.vue';
import VueMarkdown from '@/components/VueMarkdown.vue'
import ActivityScoreChip from "@/components/ActivityScoreChip";
import GroupCard from "@/components/GroupCard";
import PhotoGallery from "@/components/PhotoGallery";
import CountDownTimer from "@/components/generic/CountDownTimer";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Event",
  components: {
    VueMarkdown,
    ShareButton,
    ActivityUploadForm,
    ActivityScoreChip,
    RaceGrid,
    RaceCustomView,
    EventJoinCard,
    EventHeader,
    GroupCard,
    PhotoGallery,
    CountDownTimer,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      profile: null,
      photos: null,
      tileScores: null,
      showMuted: false,
      showShareBib: false,
      bibImageUrl: false,
      joinStatus: null,
    };
  },
  async mounted() {
    await this.loadEvent(this.$route.params.id);
    
    EventBus.$on('login-state-change', async user => {
      //console.log('LOGIN CHANGED', user, this);
    });
    
    EventBus.$on('event-status-change', async status => {
      this.joinStatus = status;
    });
  },
  methods: {
      
    async loadEvent(id) {
      var draft = this.$route.query.draft;
      var response;
      try {
        response = await eventService.get(id, draft);
      }
      catch (ex) {
        console.log('/// ex', ex, 'status:', ex.response.status, 'data', ex.response.data)
        if (ex.response && ex.response.status === 404) {
          var responseData = ex.response.data;
          if (responseData && responseData.redirect_url){
            // tenant switch
            window.location.href = responseData.redirect_url;
          }
        }
        return;
      }
      this.event = response.data;
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      if (this.$route.query.share === 'bib') {
        this.showShareBib = true;
        this.bibImageUrl = this.$route.query.img || this.event.img;
        meta = {
          title: `My virtual bib for the ${this.event.name}`,
          description: `I'm going to start the ${this.event.name} on ${tenant.title}`,
          image: this.bibImageUrl,
        }; 
      }

      EventBus.$emit('page-header-change', meta);
      this.photos = this.event.allow_photos ? (await eventService.getPhotos(id)).data.data : null;
      //this.tileScores = (await eventService.getTileScores(id)).data.data;
    },
    canShowRaceToParticipant(race, status) {
      if (!race.participants_only) {
        return true;
      }
      if (!status || !status.connected) {
        return false;
      }
      return status.race_ids == null || status.race_ids.includes(race.id);
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        this.event.org ? { text: this.event.org.name, to: {name: 'organization', params: {id: this.event.org.id}}} : null,
        { text: this.event.name, disabled: true },
      ].filter(x => x!= null);
    },
    visibleRaces() {
      return this.event && this.event.races && this.event.races.filter(x => x.visibility !== 'PRIVATE' && this.canShowRaceToParticipant(x, this.joinStatus));
    },
    hasMutedRaces() {
      return this.visibleRaces && this.visibleRaces.some(x => x.visibility === 'MUTED');
    },
    series() {
      return this.event == null || this.event.linked_groups == null ? [] : this.event.linked_groups.filter(x => x.type === 'SERIES');
    },
    organizations() {
      return this.event == null || this.event.linked_groups == null ? [] : this.event.linked_groups.filter(x => x.type === 'ORGANIZATION');
    },
    ...mapGetters({
      user: "user"
    })
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .registration-panel {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%, rgba(0,0,0,.1) 100%) !important;
  }
</style>

